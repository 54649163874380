import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Navigation`}</h4>
    <p>{`Make sure to have your phone's GPS service turned on and that you have
`}<a parentName="p" {...{
        "href": "https://support.apple.com/en-us/HT207092"
      }}>{`given permission to your web browser to use the GPS unit`}</a>{`.`}</p>
    <br />
    <p>{`Trailguide will ask for your location when you press the button, and will
not record any location data in the background. Read more about our privacy
policy `}<a parentName="p" {...{
        "href": "/privacy.pdf"
      }}>{`here`}</a>{`.`}</p>
    <br />
    <p>{`If you are using an iPhone, make sure you
`}<a parentName="p" {...{
        "href": "https://support.apple.com/en-us/HT207092"
      }}>{`activate the location service for your Safari web browser`}</a>{`.`}</p>
    <br />
    <p>{`Click on the location icon in the lower left of the screen. The purple dot shows
your location. The outer circle shows the accuracy. If it is large, the signal is
not very accurate.`}</p>
    <Image src="features/navigation2.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`If the location indication is inaccurate, try clicking the button multiple
times, this will usually get you an increasingly better location indication.`}</p>
    <br />
    <p>{`The quality and the speed of the GPS totally depends on your phones hardware!
Trailguide can only ask for the location and display it. Cheaper phones can
often weaker hardware and therefore problems providing an accurate location.`}</p>
    <br />
    <br />
    <h4>{`Offline`}</h4>
    <p>{`In theory data you loaded once is cached and available offline. The trails,
pictures, description, the base map (only the map sections and zoom levels
you loaded recently).`}</p>
    <br />
    <p>{`BUT! Don't rely on this for offline navigation in the mountains! In this
case download the GPX file and use it on your GPS device.`}</p>
    <br />
    <p>{`Tip: it works the best if you load what you need, then turn of your
mobile data to prevent your phone from trying to load new data.`}</p>
    <br />
    <Image src="features/offline.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/list" mdxType="Link">
  ← List
    </Link>
    <Link className="float-right" to="/features/downloadtrack" mdxType="Link">
  Download GPX track →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      